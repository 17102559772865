$(function($){
  $('.selectbox').on('click', function(e){
    e.stopPropagation();
    if($(this).next().hasClass('active_tab')){
      $(this).next().removeClass('active_tab');
    }else{
      $('.language__select').removeClass('active_tab');
      $(this).next().addClass('active_tab');
    }
    if($(this).hasClass('active_lang')){
      $(this).removeClass('active_lang');
    }else{
      $('.selectbox').removeClass('active_lang');
      $(this).addClass('active_lang');
    }
  });

  $('.language__select li').on('click', function(e){
    e.stopPropagation();
    $('.language__select').removeClass('active_tab');
    $('.selectbox').removeClass('active_lang');

    if(!($(this).hasClass('activelang'))){
      $(this).siblings().removeClass('activelang');
      $(this).addClass('activelang');
    }
  });

  $('.selectbox-outer .option').on('click', function(e){
    e.stopPropagation();
    var children = $(this).children().clone();
    $(this).find(".selectbox").attr("value",$(this).attr("value"));
    $(this).parent().parent().find(".selectbox").attr("value",$(this).attr("value"));
    $(this).parent().prev().html('');
    $(this).parent().prev().append(children);
    $(this).parent().hide();
  });

  // Automatically change language to English when customer selects International territory
  $('.selectbox-outer.territory-input .select-field.language__select li').on('click', function(){
    const territoryElement = $(this).parent().parent();
    if(territoryElement.find('.selectbox.current-territory').attr('value') == "in"){
      const formElement = territoryElement.parent().parent();
      formElement.find('.current-language').attr("value", "en").html('')
      .append($('.option[value|="en"]').first().children().clone());
      formElement.find('.language-input .option').removeClass('activelang');
      formElement.find('.language-input .option[value|="en"]').addClass('activelang');
    }
  });
});
